/* global fbq */

import { useEffect } from 'react';

function useFacebookPixel() {
  const trackAddToCart = (productId, price) => {
    fbq('track', 'AddToCart', {
      content_ids: [productId],
      value: price,
      currency: 'CAD',
      content_type: 'product',
    });
  };

  const trackPurchase = (productId, price) => {
    fbq('track', 'Purchase', {
      content_ids: [productId],
      value: price,
      currency: 'CAD',
      content_type: 'product',
    });
  };

  useEffect(() => {
    fbq('track', 'PageView');
  }, []);

  return { trackAddToCart, trackPurchase };
}

export default useFacebookPixel;