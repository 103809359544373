import { React, useEffect, useState } from 'react';
import { axiosMailChimpInstance } from '../mutations/checkout';
import ClipLoader from "react-spinners/ClipLoader";

import '../css/Contest.css';

const Contest = () => {
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('');
    const [nearestStore, setNearestStore] = useState('');

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [emptyName, setEmptyName] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [resMessage, setResMessage] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);



    const handleUserName = (event) => {
        setUserName(event.target.value);
        setEmptyName(false);
    };

    const handleUserEmail = (event) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(event.target.value);

        if (isValidEmail || event.target.value === "") {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
        setUserEmail(event.target.value);
        setEmptyEmail(false);
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        if (!userName || !userEmail) {
            if (!userName) {
                setEmptyName(true);
            }
            if (!userEmail) {
                setEmptyEmail(true);
            }
            return;
        }
        registerGuestToMailChimp();
    };

    const registerGuestToMailChimp = async () => {
        console.log("data before subscribe: ", userName, userEmail)
        const data = {
            'email': userEmail,
            'name': userName,
            'termsAccepted': true,
            'nearestStore': nearestStore,
            'tagName': 'Ed Harris'
        }
        try {
            setIsLoading(true);
            const res = await axiosMailChimpInstance.post('/mailchimp/register', data);
            if (res.status === 200) {
                setIsLoading(false);
                setIsSuccess(true);
                setResMessage('Thank you for your engagement!');
                console.log('Successfully subscribed.');
                console.log(res);
                setTimeout(() => {
                    localStorage.setItem('isSubscribed', JSON.stringify(true));
                    setIsSubscribed(true);
                    setIsSuccess(false);
                    window.location.href = '/shop';
                }, 2000);
            }
            // else if (res.status === 400 && res.data.code === 'mailchimp_permanently_deleted') {
            //   // user has been permenantly deleted, have to use the MailChimp portal to re-subscribe
            //   window.location.href = 'http://eepurl.com/haDB35';
            // } 
            else {
                // console.log('Failed to subscribe.');
                setIsLoading(false);
                setIsSuccess(true);
                setResMessage(res.data.message);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 4000);
            }
        } catch (error) {
            setIsLoading(false);
            setIsSuccess(true);
            setResMessage(error.response.data.message);
            console.log('error', error.response.data);
            if (error.response.status === 400 && error.response.data.code === 'mailchimp_subscribed') {
                setResMessage('Thank you for your engagement!');
                setTimeout(() => {
                    localStorage.setItem('isSubscribed', JSON.stringify(true));
                    setIsSubscribed(true);
                    setIsSuccess(false);
                    window.location.href = '/shop';
                }, 2000);
            }
            setTimeout(() => {

                setIsSuccess(false);
            }, 4000);
        }
    };

    return (
        <div>
            <div className='contest-wrapper'>
                <div className='contest-info'>
                    <div className='contest-banner-image' style={{ backgroundImage: 'url(./images/contest_banner_bg.png)' }}></div>
                    <div className='contest-content'>
                        <h2 className="contest-title">Fisherman's Market</h2>
                        <h3 className="contest-subtitle">Your Trusted leader in BC Seafood</h3>
                        <p>Get Ready to Reel in Some Savings!</p>
                        <p style={{ lineHeight: '1.5' }}>Fisherman’s Market is giving away a $250 gift card every month to one lucky contest entrant! This is your chance to stock up on premium seafood and enjoy the freshest catch.</p>
                        <p>Enter now for your chance to win and experience the best that Fisherman’s Market has to offer. Don’t miss out—get hooked on savings today!</p>
                    </div>
                </div>
                <div className='contest-form'>
                    <h1 style={{ textAlign: 'center' }}><img height='120' src='./logo192.png' /></h1>
                    {isLoading ?
                        <div className="contest-loading"><ClipLoader size={30} color='#173050' data-testid="loader" /><p>Signing up</p></div> :
                        isSuccess ? <div className="contest-loading"><p>{resMessage}</p></div> :
                            <>
                                {/* <div style={{margin: '2rem 0 2rem'}}>
                                    <p style={{ margin: '.25rem .5rem' }}>Contest ends on October 1, 2024.</p>
                                    <p style={{ margin: '.25rem .5rem' }}>Enter once per day.</p>
                                    <p style={{ margin: '.25rem .5rem' }}>For full list of conditions and details click on rules at the bottom of the page.</p>
                                </div> */}
                                <div className='contest-input'>
                                    <div className='contest-input-item'>
                                        <input className={emptyName ? 'empty' : undefined} type="text" placeholder="Full Name (Required)" value={userName} onChange={handleUserName} />
                                    </div>
                                    <div className='contest-input-item'>
                                        <input className={emptyEmail ? 'empty' : undefined} type="email" placeholder="Email (Required)" value={userEmail} onChange={handleUserEmail} />
                                        {!isValidEmail && <span className="invalid-email-msg">Please enter a valid email address.</span>}
                                    </div>
                                    <div className='contest-input-item'>
                                        <select value={nearestStore} onChange={e => setNearestStore(e.target.value)} >
                                            <option value="">Select a store near you</option>
                                            <option value="Nelson">Nelson</option>
                                            <option value="Gibsons">Gibsons</option>
                                            <option value="Kelowna">Kelowna</option>
                                            <option value="Kamloops">Kamloops</option>
                                            <option value="West Vancouver">West Vancouver</option>
                                            <option value="Burnaby">Burnaby</option>
                                            <option value="I don&#039;t live close to any of the stores">I don't live close to any of the stores</option>
                                        </select>
                                    </div>
                                </div>
                                <button className='contest-button' onClick={handleRegistration}>ENTER TO WIN</button>
                                {/* <div className='contest-rules'>
                                    <p>By clicking the submit button below, I agree to the <Link to='/terms-conditions'>Terms of Service</Link> and <Link to='/privacy-policy'>Privacy Policy</Link></p>
                                </div> */}
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Contest;
