import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './App.css';

import ScrollToTop from './components/ScrollTotop';
import Cart from './pages/Cart';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import ProductList from './ProductList';
import Stores from './Stores';
import RecipeList from './RecipeList';
import RecipeDetail from './components/RecipeDetail';
import BlogList from './BlogList';
import BlogDetail from './components/BlogDetail';
import ProductDetail from './components/ProductDetails';
import GiftCard from './pages/GiftCard';
import Recipes from './pages/Recipes';
import NewsAndStories from './pages/NewsAndStories';
import StoreLocation from './pages/StoreLocation';
import Franchisees from './pages/Franchisees';
import AboutUs from './pages/AboutUs';
import Terms from './pages/Terms';
import Policy from './pages/Policy';
import Checkout from './pages/Checkout';
import Shipping from './pages/Shipping';
import ContactUs from './pages/ContactUs';
import History from './pages/History';
import Fundraiser from './pages/Fundraiser';
import SignIn from './pages/customer/SignIn';
import Register from './pages/customer/Register';
import Account from './pages/customer/Account';
import MyAccount from './pages/customer/MyAccount';
import ForgetPassword from './pages/customer/Forget';
import ResetPassword from './pages/customer/Reset';
import PrivateRoute from './PrivateRoute';
import CustomerInfo from './pages/checkout/CustomerInfo';
import CustomerShipping from './pages/checkout/CustomerShipping';
import CustomerBilling from './pages/checkout/CustomerBilling';
import NoPage from './pages/NoPage';
import Footer from './components/Footer';
import SignUpButtom from './components/SignUpButtom';
import { CartProvider } from './context/CartContext';
import { UserProvider } from './context/UserContext';
import PriceList from './components/PriceList';
import { CheckoutProvider } from './context/CheckoutContext';
import { Elements, CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { UtmProvider } from './context/UtmContext';
import useCaptureUtm from './hooks/useCaptureUtm';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import CheckoutSuccess from './pages/checkout/Success';
import Contest from './components/Contest';
import FestInfo from './pages/FestInfo';
import Ourfish from './pages/Ourfish';

const MainContent = () => {
    useCaptureUtm();
    const [getTotalItems] = useState();
    const location = useLocation();
    const isCheckoutPage = location.pathname === '/checkout';

    const hideFooterPaths = ['/checkout', '/customer/sign-in', '/customer/register', '/customer/forget', '/customer/reset', '/cart'];
    const isHideFooterPage = hideFooterPaths.includes(location.pathname);

    return (
        <div className="App">
            <ScrollToTop />
            {!isCheckoutPage ? <Navbar cartCount={getTotalItems} /> : ""}

            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/shop" element={<ProductList />} />
                <Route path="/locations" element={<Stores />} />
                <Route path="/recipes" element={<RecipeList />} />
                <Route path="/recipes/:recipeId" element={<RecipeDetail />} />
                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:blogId" element={<BlogDetail />} />
                <Route path="/product/:productId" element={<ProductDetail />} />
                <Route path="/terms-conditions" element={<Terms />} />
                <Route path="/privacy-policy" element={<Policy />} />
                <Route path="/gift-card" element={<GiftCard />} />
                <Route path="/store-location" element={<StoreLocation />} />
                <Route path="/recipes" element={<RecipeList />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/news-and-stories" element={<NewsAndStories />} />
                <Route path="/franchisees" element={<Franchisees />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/history" element={<History />} />
                <Route path="/fundraiser" element={<Fundraiser />} />
                <Route path="/shipping" element={<Shipping />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/our-market" element={<PriceList />} />
                <Route path="/customer/sign-in" element={<SignIn />} />
                <Route path="/customer/register" element={<Register />} />
                <Route path="/customer/forget" element={<ForgetPassword />} />
                <Route path="/customer/reset" element={<ResetPassword />} />
                <Route path="/contest" element={<Contest />}  />
                <Route path="/fest-info" element={<FestInfo />} />
                <Route path="/ourfish" element={<Ourfish />} />
                <Route path="/customer/account" element={
                    <PrivateRoute>
                        <MyAccount />
                    </PrivateRoute>
                } />
                <Route path="/checkout/success" element={<CheckoutSuccess />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="*" element={<NoPage />} />
            </Routes>

            {!isHideFooterPage ? <Footer /> : ""}
        </div>
    );
};

const App = () => {
    useCaptureUtm();
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <UtmProvider>
                <UserProvider>
                    <CheckoutProvider>
                        <CartProvider>
                            <Router>
                                <MainContent />
                            </Router>
                        </CartProvider>
                    </CheckoutProvider>
                </UserProvider>
            </UtmProvider>
        </QueryClientProvider>
    );
};

export default App;