// Function to get UTM parameters from the URL
export const getUtmParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utm_source: urlParams.get('utm_source') || 'Website Direct',
    utm_medium: urlParams.get('utm_medium') || '',
    utm_campaign: urlParams.get('utm_campaign') || '',
    utm_term: urlParams.get('utm_term') || '',
    utm_content: urlParams.get('utm_content') || '',
  };
  return utmParams;
};

// Function to store UTM parameters in local storage
export const storeUtmParameters = () => {
  const utmParams = getUtmParameters();
  localStorage.setItem('utmParams', JSON.stringify(utmParams));
};

// Function to clear UTM parameters from local storage
export const clearUtmParameters = () => {
  localStorage.removeItem('utmParams');
};

// Function to clear and repopulate UTM parameters
export const refreshUtmParameters = () => {
  clearUtmParameters();
  storeUtmParameters();
};