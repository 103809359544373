import React, { useState, useCallback, useEffect } from 'react';

import Recommends from './Recommends'
import Product from './Product'
import '../css/ProductDetails.css';
import SignUpButton from './SignUpButtom';


const ProductDetail = () => {
    
    return (
        <div className="product-detail">
            <Product />

            <Recommends />
            <SignUpButton />
        </div >
    );
};

export default ProductDetail;


