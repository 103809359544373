import React from 'react';
import '../css/Terms.css';
import SignUpButton from '../components/SignUpButtom';
import { Link } from 'react-router-dom';

import aboutusBanner from '../images/FMS_images-13-scaled.webp';
import staff1Bg from '../images/FMS_images-15.webp';
import staff1Fg from '../images/FMS_images-14.webp';
import staff2Bg from '../images/FMS_images-17.webp';
import staff2Fg from '../images/FMS_images-16.webp';
import staff3Bg from '../images/FMS_images-19.webp';
import staff3Fg from '../images/FMS_images-18.webp';
import staff4Bg from '../images/FMS_images-22.webp';
import staff4Fg from '../images/FMS_images-20.webp';
import staff5Bg from '../images/FMS_images-25.jpg';
import staff5Fg from '../images/FMS_images-26.jpg';
import staff6Bg from '../images/FMS_images-23.jpg';
import staff6Fg from '../images/FMS_images-24.jpg';
import spanImg1 from '../images/commercial_fishing_2.jpg'
import spanLogo from '../images/FMS_horizontal-logo-RGB-white.svg';
import PopUp from '../components/PopUp';


export default function AboutUs() {

  return (
    <div className="terms">

      {/* <div className="terms-banner">
        <h2>About us</h2>
      </div> */}

      <div className="terms-wrapper" style={{maxWidth: "1350px"}}>
        <div className="terms-body">
          <div className="terms-body-section">
            <h2 style={{textAlign: "center", fontSize: "40px"}}>About Us</h2>
            <p className="content-center">
              The Fisherman’s Market ship symbolizes our core beliefs that quality, sustainability, and affordability can be a reality. That passing along the knowledge of where our fish comes from helps preserve the future of our beautiful coastline. Sustainability brings us closer to the environment. Long lines bring us closer to the fisherman. Direct contacts bring us closer to affordability. An experienced eye brings us closer to the freshest product. Small business brings us closer to the community.
            </p>
            <p className="content-center" style={{fontSize: "24px", fontStyle: "Italic", color: "#173050"}}>Sincerity brings us closer to our customers.</p>
            <p className="content-center" style={{fontSize: "24px", fontStyle: "Italic", color: "#173050"}}>We bring everything one step closer to you.</p>

          </div>

        </div>
      </div>

      <div className="aboutus-banner" style={{ backgroundImage: 'url(' + aboutusBanner + ')' }}>
        <div className="aboutus-banner-wrapper">
          <div className="aboutus-banner-content">
            <h2>The Fisherman’s Market history</h2>
            <p>Raised in Comox, BC, Geoff Austin and Adam Pearl have been fishing together since their teens, first on Adam’s father’s boat and later on fishing vessels they captained. The two young men shared a strong work ethic, accommodations, travel, and fishing expeditions throughout their teens and twenties. </p>
            <p>Despite a shipwreck that changed the course of their careers, the North Pacific Ocean still draws the pair. Once a fisherman, always a fisherman.</p>
            <Link to="/history" className="aboutus-banner-content-button">READ MORE</Link>
          </div>
        </div>
      </div>
      <div className="terms-wrapper" style={{maxWidth: "1350px"}}>
        <div className="aboutus-section">
          <div className="aboutus-section-image">
            <div className="aboutus-section-image-bg">
              <img src={staff1Bg} alt=""/>
              <div className="aboutus-section-image-fg">
                <img src={staff1Fg} alt=""/>
              </div>
            </div>

          </div>
          <div className="aboutus-section-bio">
            <h3>Adam Pearl</h3>
            <h4>Procurement and Operations</h4>
            <p>Adam's upbringing in the fishing industry, starting from a young age helping his father sell seafood off the dock, instilled in him the values of integrity, respect for the ocean, and a commitment to providing customers with the best seafood possible. These values continue to guide him in his role as Head of Procurement and Operations for Fisherman’s Market.</p>
            <p>Adam's hands-on experience as a fisherman himself allows him to stay current with sustainable seafood practices and ensures that Fisherman’s Market offers only the highest quality products to its customers. His intimate ties to the North Pacific coast provide him with valuable insights into the seafood industry, from the catch itself to the logistics of getting it from the ocean to the table.</p>
            <p>Adam's passion for Fisherman’s Market, his customers, and his employees shines through in every aspect of his work. Whether he's strategizing ways to streamline seafood flow or spending time with his sons near a body of water in and around Nelson, BC, Adam's dedication to his craft and his community is evident.</p>
            <p>Adam's story exemplifies the deep connection between family, tradition, and the fishing industry. His commitment to sustainability and excellence ensures that Fisherman’s Market continues to uphold its reputation as a trusted source of fresh, responsibly sourced seafood.</p>
          </div>
        </div>
      </div>
      
      <div className="aboutus-span">
        <div className="aboutus-span-wrapper">
          <div className="aboutus-span-images">
            <div className="span-images" style={{backgroundImage: 'url(/images/blog/FishermansMarket_BCCFW_lingcod_sustainable_fishing_AdamPearl.webp)'}}></div>
            <div className="span-images" style={{backgroundImage: `url(${spanImg1})`}}></div>
          </div>
          <div className="aboutus-span-content">
            <img src={spanLogo} alt=""/>
            <Link to="/shop">SHOP OUR FISH NOW</Link>
            <div>
              <p className="pulpo-rust">
                <span>CATCH</span>
                <span>CARE</span>
                <span>CONNECT</span>
              </p>
              <p>Expertise in Every Step</p>
            </div>
          </div>
        </div>
      </div>

      <div className="terms-wrapper" style={{maxWidth: "1350px"}}>
        <div className="aboutus-section">
          <div className="aboutus-section-image">
            <div className="aboutus-section-image-bg">
              <img src={staff2Bg} alt=""/>
              <div className="aboutus-section-image-fg">
                <img src={staff2Fg} alt=""/>
              </div>
            </div>

          </div>
          <div className="aboutus-section-bio">
            <h3>Geoff Austin</h3>
            <h4>Finance and Facilities</h4>
            <p>Geoff's lifelong passion for fishing and the solitude it offers has shaped both his personal and professional life. His experience as a commercial fisherman has provided him with invaluable knowledge of Canada's North Pacific fishing industry and a deep commitment to sustainable harvesting practices.</p>
            <p>In his role as Financial Controller for Fisherman’s Markets, Geoff applies his expertise to ensure the financial health and success of the company. Additionally, his passion for design, building, and renovations is reflected in the aesthetic and atmosphere of all the Fisherman’s Market stores. By marrying his love for design with the company's ethos of sustainability and support for Canadian fisher folk, Geoff contributes to creating a welcoming environment that aligns with Fisherman’s Market's values.</p>
          </div>
        </div>
      </div>

      <div className="terms-wrapper" style={{maxWidth: "1350px"}}>
        <div className="aboutus-section">
          <div className="aboutus-section-image">
            <div className="aboutus-section-image-bg">
              <img src={staff3Bg} alt=""/>
              <div className="aboutus-section-image-fg">
                <img src={staff3Fg} alt=""/>
              </div>
            </div>

          </div>
          <div className="aboutus-section-bio">
            <h3>Cory Siegner</h3>
            <h4>Sales and Marketing</h4>
            <p>Cory's upbringing and early exposure to the fishing industry laid the foundation for his career in marketing and sales, where he now plays a crucial role in making seafood accessible to customers. His firsthand experience working in a fish packing plant and accompanying his family to industry events instilled in him a deep appreciation for the fishing economy and the importance of sustainable practices.</p>
            <p>As the head of Marketing and Sales for both wholesale national accounts and direct customers, Cory is dedicated to ensuring that Fisherman’s Market provides the freshest seafood while upholding principles of sustainability and transparency. His passion for the industry is evident in his creative marketing initiatives, such as the oyster shucking program for the Vancouver Aquarium's fundraiser and hosting a cooking show on Global TV.</p>
            <p>Outside of work, Cory's love for the outdoors and spending time with his family reflects his connection to nature and his commitment to a balanced lifestyle. Whether playing tennis, golf, hiking, or skating with his kids in Kelowna, BC, Cory finds joy in sharing his passions with his family and instilling in them the same appreciation for the natural world that inspired his own career path.</p>
          </div>
        </div>
      </div>

      <div className="terms-wrapper" style={{maxWidth: "1350px"}}>
        <div className="aboutus-section">
          <div className="aboutus-section-image">
            <div className="aboutus-section-image-bg">
              <img src={staff4Bg} alt="" />
              <div className="aboutus-section-image-fg">
                <img src={staff4Fg} alt=""/>
              </div>
            </div>

          </div>
          <div className="aboutus-section-bio">
            <h3>Katie Budd</h3>
            <h4>West Coast Sales Manager</h4>
            <p>Katie's journey with Fisherman’s Market reflects her deep connection to the seafood industry and her passion for sharing her knowledge and love of the ocean with others. Starting at the Kamloops location in 2013 and eventually transitioning to Vancouver operations to help open our markets in West Vancouver, Gibsons, and Burnaby demonstrates her adaptability and commitment to the company's growth.</p>
            <p>Her experience as a spot prawn fisherman from a young age gives her a unique perspective and expertise in the seafood industry, which she brings to her role at the market. Her dedication and hard work in managing multiple market locations and inspiring both customers and Crew highlight her leadership skills and positive influence within the company.</p>
            <p>Katie's genuine love for fishing and the ocean shines through in her interactions with customers and her enthusiasm for sharing information about the industry. Her ability to inspire others to look at seafood in a new light speaks to her passion and dedication to promoting sustainable and high-quality seafood to both her retail and wholesale customers.</p>
          </div>
        </div>
      </div>

      <div className="terms-wrapper" style={{maxWidth: "1350px"}}>
        <div className="aboutus-section">
          <div className="aboutus-section-image">
            <div className="aboutus-section-image-bg">
              <img src={staff5Bg} alt="" />
              <div className="aboutus-section-image-fg">
                <img src={staff5Fg} alt=""/>
              </div>
            </div>

          </div>
          <div className="aboutus-section-bio">
            <h3>Gina Fedora</h3>
            <h4>Head of Market Operations</h4>
            <p>Gina Fedora's extensive experience as a fishmonger and her dedication to her craft have earned her recognition as one of the best on the West Coast. Her journey from northern Saskatchewan to the bustling fish markets of the West Coast reflects her passion for the seafood industry and her commitment to excellence.</p>
            <p>Starting her career at just 15 years old in her hometown fish market, Gina honed her skills over the years, eventually landing a job at the renowned Salmon Shop in Granville Island at the age of 21. For over two decades, Gina dedicated herself to running the Salmon Shop, where she built a successful product line and became known for her expert filleting skills.</p>
            <p>In 2013, Gina's expertise caught the attention of Fisherman’s Market as they expanded, leading her to take on the role of manager at their Kamloops location. Under her leadership, the Kamloops market quickly became a well-known and respected establishment within the community.</p>
            <p>Now, Gina brings her wealth of knowledge and experience to Fisherman’s Market's head office, where she is focused on streamlining operations and maintaining high standards across the company. Her meticulous attention to detail and commitment to excellence make her an invaluable asset to the Fisherman’s Market team.</p>
          </div>
        </div>
      </div>

      <div className="terms-wrapper" style={{maxWidth: "1350px"}}>
        <div className="aboutus-section">
          <div className="aboutus-section-image">
            <div className="aboutus-section-image-bg">
              <img src={staff6Bg} alt="" />
              <div className="aboutus-section-image-fg">
                <img src={staff6Fg} alt=""/>
              </div>
            </div>

          </div>
          <div className="aboutus-section-bio">
            <h3>Jeremy Wheeler</h3>
            <h4>Operations and Finance Compliance Manager</h4>
            <p>Jeremy Wheeler's tenure with Fisherman’s Market is truly impressive and reflects his dedication and versatility within the company. Starting as a line cook at the Dock Restaurant in 2007 and transitioning to working on fishing vessels demonstrates his hands-on experience and commitment to the seafood industry. His transition to management and subsequent involvement in market operations and finance highlights his adaptability and willingness to take on new challenges.</p>
            <p>Jeremy's long-standing tenure since 2007 speaks volumes about his loyalty and contributions to Fisherman’s Market. Being the longest-standing Crew member shows his deep connection to the company and its values. His role in helping with the establishment of Fisherman’s Market head office underscores his importance in shaping the company's growth and organizational structure.</p>
            <p>His colleagues' recognition of Jeremy as an "invaluable resource" and someone always ready to help with any task reflects his professionalism, reliability, and willingness to support his team. Jeremy's vast skill set and willingness to tackle tasks, both big and small, make him an indispensable member of the Fisherman’s Market family.</p>
          </div>
        </div>
      </div>

      <SignUpButton />
      <PopUp/>
    </div>

  )
}
