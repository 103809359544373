import React, { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import '../css/CustomerReviews.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const CustomerReviews = () => {
    const [favoriteData, setFavoriteData] = useState([]);

    const reviews = [
        { name: '-Rob and Mary W, Kelowna', review: ['I looked at my wife as we walked in the store and we both said the same thing, it’s so clean in here and it doesn\'t smell fishy','We order online and are always quickly helped by the Crew when we walk in, we love the sales. Just put 7lbs of Tanner Crab Legs in the freezer!'], rating: 5 },
        { name: '-Travis L, Kelowna', review: ['This is my go-to for seafood. I’m always taken care of when I walk in the door, their sales are top notch and they offer a selection that keeps my dinner table full week after week! Crab, scallops, prawns….Stay deadly Fisherman’s Market !!'], rating: 5 },
        { name: 'Alison F, West Van', review: ['If you are looking for “really” fresh, high-quality seafood, this is the spot! The crew knows their fish!  The vibe from this shop is very happy and upbeat. The quality is simply the best.', 'These folks live and breathe seafood- and that’s an ideal seafood vendor.'], rating: 5 },
        { name: '-Rick A, Gibsons', review: ['Fisherman’s Market is our go-to place for everything seafood. Fantastic products and exceptional service. I would highly recommend to everyone'], rating: 5 },
        { name: '-Leslie and Dave, Kamloops', review: ['We have had the utmost pleasure over the past 10 years purchasing all of our seafood from our fishmonger Gina and her crew. The products are exceptional and the service is above and beyond. We would highly recommend Fisherman’s Market for top-quality seafood.'], rating: 5 },
        { name: '-Jason B, Kamloops', review: ['Fisherman’s Market stands as the best fishmonger I have ever bought from. This is a proper fish market with incredibly knowledgeable crews. The products are of the highest quality. I leave the store feeling good that the seafood I buy is not only fresh but also sustainably caught. Fisherman\'s Market is the only place I buy my seafood!'], rating: 5 },
        { name: '-Len A, Nelson BC', review: ['Fisherman’s Market is on par with any fish market one would find in a much larger urban center. The selection of products and the quality are excellent. The pre-prepared selection is always fresh and of the highest quality.'], rating: 5 },
        { name: '-Linda K, Kelowna', review: ['We were one of the first customers at Fisherman’s Market and have been regulars for over 12 years. The quality of service and products have been outstanding. They treat all customers like family. They offer an exceptional variety of seafood as well as farm-direct meats, and specialty chicken. Fisherman’s Market is our exclusive place to shop.'], rating: 5 },
    ]


    return (
        <div className="product-reviews">
            <div className="product-reviews-wrapper">
                <div className='product-reviews-title'>
                    <p className="favorite-titel">Customer Testimonials</p>
                </div>
                <Swiper
                    slidesPerView={window.innerWidth > 1024 ? 3 : 1}
                    spaceBetween={50}
                    loop={true}
                    centeredSlides={true}
                    centerInsufficientSlides={true}
                    centeredSlidesBounds={true}
                    modules={[Autoplay, Pagination, Scrollbar, A11y]}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                      }}
                    className="mySwiper"
                >
                    {reviews.map((review, index) => (
                        <SwiperSlide key={index} className="reviews">
                            <div className='rating-wrapper'>
                                {Array.from({ length: review.rating }).map((_, index) => (
                                    <FontAwesomeIcon key={index} icon={faStar} />
                                ))}
                            </div>
                            <div className="review-info">
                                {review.review.map((review, index) => (
                                    <p key={index} className="review-text">{review}</p>
                                ))}
                                <p className="reviewer-name">{review.name}</p>  
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default CustomerReviews;
