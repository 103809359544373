const blogData = [
    {
        id: 1,
        title: 'BCCFW: Sustainably caught lingcod',
        author: 'Fisherman\'s Market',
        date: 'January 30, 2023',
        type: ['BC Commercial Fishing Week'],
        abstract: 'Lingcod is a large, meaty fish with a firm texture and a mild, slightly sweet flavour. It is often prepared in similar ways as halibut, like grilling, baking, or pan-frying.',
        main: [
            {
                id: 1,
                subTitle: '',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_lingcod_sustainable_fishing_AdamPearl.webp',
                list: [
                    'At Fisherman’s Market, we are committed to providing our customers with the highest quality, sustainably caught seafood. During this year’s BC Commercial Fishing Week (BCCFW), we want to take the opportunity to highlight one of our delicious and sustainably caught species: lingcod.',
                    'Lingcod is a species found in the Pacific Ocean and is often caught using troll and longline fishing. These methods are sustainable because they allow for selective harvesting and have low bycatch rates. This means that only the desired size and species of fish are caught, minimizing the impact on the ecosystem and other marine life.',
                    'Lingcod is a large, meaty fish with a firm texture and a mild, slightly sweet flavour. It is often prepared in similar ways as halibut, like grilling, baking, or pan-frying. It is also a good source of protein and omega-3 fatty acids, making it a healthy and delicious choice for everyone.',
                    'British Columbia is home to some of the most well-managed lingcod fisheries in the world, and we are proud to source our lingcod from local, sustainable fisheries. The lingcod populations are healthy and well-managed by regional fishery management organizations, ensuring that the fish are caught in a responsible manner.',
                    'At Fisherman’s Market, we believe that sustainability is not only an environmental responsibility, but also an economic and social one. It is our duty to ensure that the seafood we provide to our customers is not only delicious and high quality, but also responsibly and sustainably sourced. We hope that our customers will join us in celebrating BCCFW and learning more about the importance of sustainable fishing in British Columbia.',
                    'We invite you to try some of our delicious lingcod during BCCFW, and experience the taste of sustainably caught seafood. If you have any questions about our sustainable fishing practices or the lingcod we offer, please do not hesitate to ask us.'
                ]
            },
            {
                id: 2,
                subTitle: 'Lingcod: A history of sustainable fishing in BC',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_lingcod_sustainable_fishing_KatieBudd.webp',
                list: [
                    'Lingcod, also known as Ophiodon elongatus, is a popular species found in the Pacific Ocean and is a staple in the British Columbia seafood industry. The lingcod fishery in British Columbia has a long history of sustainable practices, dating back to the early 20th century.',
                    'Historically, longlining was the primary method of catching lingcod in British Columbia. Longlining is a sustainable fishing method as it allows for selective harvesting, and it has minimal impact on the seafloor. Longlines are baited hooks set in specific areas to target lingcod. The hooks are designed to allow undersized and non-target species to escape, minimizing bycatch.',
                    'In the late 1970s and early 1980s, the British Columbia government began implementing regulations to ensure the sustainable management of the lingcod fishery. This included measures such as size limits and quotas to prevent overfishing, as well as the use of specific gear types to minimize bycatch.',
                    'In recent years, the British Columbia lingcod fishery has been recognized for its sustainable practices. Organizations such as the Marine Stewardship Council (MSC) have certified the fishery as sustainable, based on strict environmental and management criteria. At Fisherman’s Market, we are proud to source our lingcod from local, sustainable fisheries that have been certified by the MSC. We encourage our customers to support sustainable fishing by choosing lingcod from British Columbia.'
                ]
            }
        ],
        imgUrl: '/images/blog/FishermansMarket_BC_Commercial_Fishing_Week_sustainable_fishing_lingcod-scaled.webp'
    },
    {
        id: 2,
        title: 'BCCFW: Sustainably caught albacore tuna',
        author: 'Fisherman\'s Market',
        date: 'January 30, 2023',
        type: ['BC Commercial Fishing Week'],
        abstract: 'Trolling is a sustainable fishing method because it uses hooks and lures to target specific fish, rather than nets that can catch a wide variety of marine life.',
        main: [
            {
                id: 1,
                subTitle: '',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_sustainable_fishing_halibut.webp',
                list: [
                    'At Fisherman’s Market, we are committed to providing our customers with the highest quality, sustainably caught seafood. During this year’s BC Commercial Fishing Week (BCCFW), we want to take the opportunity to highlight one of our most popular and sustainably caught species: albacore tuna.',
                    'Albacore tuna is a species found in the Pacific Ocean and is often caught using trolling. Trolling is a sustainable fishing method because it uses hooks and lures to target specific fish, rather than nets that can catch a wide variety of marine life. This method of fishing allows for selective harvesting, ensuring that only the desired size and species of fish are caught.',
                    'In addition to its sustainability, albacore tuna is also known for its delicious taste and nutritional benefits. The meat is firm and flavourful with a mild taste, making it a versatile ingredient in many dishes such as sushi, sashimi, and grilled or seared entrees. It is also a good source of protein and omega-3 fatty acids, making it a healthy choice for customers.',
                    'British Columbia is home to some of the most well-managed albacore tuna fisheries in the world, and we are proud to source our albacore tuna from local, sustainable fisheries. The albacore tuna populations are healthy and well-managed by regional fishery management organizations, ensuring that the fish are caught in a responsible manner.',
                    'At Fisherman’s Market, we believe that sustainability is not only an environmental responsibility, but also an economic and social one. It is our duty to ensure that the seafood we provide to our customers is not only delicious and high quality, but also responsibly and sustainably sourced. We hope that our customers will join us in celebrating BCCFW and learning more about the importance of sustainable fishing in British Columbia.',
                    'We invite you to try some of our delicious albacore tuna during BCCFW, and experience the taste of sustainably caught seafood. If you have any questions about our sustainable fishing practices or the albacore tuna we offer, please do not hesitate to ask us.'
                ]
            },
            {
                id: 2,
                subTitle: 'Albacore tuna: A history of sustainable fishing in BC',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_sustainable_fishing_whole_albacore_tuna.webp',
                list: [
                    'Albacore tuna, also known as Thunnus alalunga, is a popular species found in the Pacific Ocean and is a staple in the British Columbia seafood industry. The albacore tuna fishery in British Columbia has a long history of sustainable practices, dating back to the early 20th century.',
                    'Historically, trolling was the primary method of catching albacore tuna in British Columbia. Trolling is a sustainable fishing method as it does not require using large nets that can damage the seafloor, and it allows for selective harvesting. Trolling involves pulling lures or baited hooks behind a boat to target specific species of fish, in this case, albacore tuna. This method allows for the release of any undersized or non-target species, minimizing bycatch.',
                    'In the 1990s, the International Albacore Tuna Organization (IATO) was established to manage the albacore tuna fishery in the Pacific Ocean and ensure its sustainability. The IATO sets quotas and regulates the season, to ensure that the albacore tuna population is fished at sustainable levels. At Fisherman’s Market, we are proud to source our albacore tuna from local, sustainable fisheries that follow responsible fishing practices set by the IATO. We encourage our customers to support sustainable fishing by choosing albacore tuna from British Columbia.'
                ]
            }
        ],
        imgUrl: '/images/blog/FishermansMarket_BC_Commercial_Fishing_Week_sustainable_fishing_albacore_tuna-scaled.webp'
    },
    {
        id: 3,
        title: 'BCCFW: Sustainably caught spot prawns',
        author: 'Fisherman\'s Market',
        date: 'January 30, 2023',
        type: ['BC Commercial Fishing Week'],
        abstract: 'At Fisherman’s Market, we are proud to source our spot prawns from local, sustainable fisheries that follow responsible fishing practices.',
        main: [
            {
                id: 1,
                subTitle: '',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_spot_prawns_sustainable_fishing_AdamPearl.webp',
                list: [
                    'At Fisherman’s Market, we are committed to providing our customers with the highest quality, sustainably caught seafood. During this year’s BC Commercial Fishing Week (BCCFW), we want to take the opportunity to highlight one of our most popular and sustainably caught species: spot prawns.',
                    'Spot prawns are a species found in the Pacific Ocean and are often caught using trap fishing. Traps are a sustainable fishing method because they allow for selective harvesting and do not cause damage to the seafloor. Spot prawn traps are baited with fish or squid and are set in specific areas to target the prawns. Traps are designed to allow undersized and non-target species to escape, minimizing bycatch.',
                    'Spot prawns are known for their sweet, delicate flavour and firm texture. They are often used in sushi, and can also be grilled, sautéed, or used in pasta dishes. They are also a good source of protein, vitamins and minerals.',
                    'British Columbia is home to some of the world’s most well-managed spot prawn fisheries, and we are proud to source our spot prawns from local, sustainable fisheries. The spot prawn populations are healthy and well-managed by regional fishery management organizations, ensuring that the prawns are caught in a responsible manner.',
                    'At Fisherman’s Market, we believe that sustainability is not only an environmental responsibility, but also an economic and social one. It is our duty to ensure that the seafood we provide to our customers is not only delicious and high quality, but also responsibly and sustainably sourced. We hope that our customers will join us in celebrating BCCFW and learning more about the importance of sustainable fishing in British Columbia.',
                    'We invite you to try some of our delicious spot prawns during BCCFW, and experience the taste of sustainably caught seafood. If you have any questions about our sustainable fishing practices or the spot prawns we offer, please do not hesitate to ask us. We at Fisherman’s Market are committed to bringing you the best sustainably caught seafood in BC. As we celebrate this year’s BCCFW, we want to remind our customers that sustainable fishing not only helps to preserve the marine environment, but also supports local communities and the livelihoods of fishermen and their families. We are proud to be part of this tradition and to continue to support sustainable fishing practices in British Columbia. So, come and join us in celebrating the BCCFW and taste the difference in our sustainably caught seafood.'
                ]
            },
            {
                id: 2,
                subTitle: 'Spot Prawns: A history of sustainable fishing in BC',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_spot_prawns_sustainable_fish.webp',
                list: [
                    'Spot prawns, also known as Pandalus platyceros, is a popular species found in the Pacific Ocean and is a staple in the British Columbia seafood industry. The spot prawn has a long history of sustainable fishing in British Columbia, dating back to the early 20th century.',
                    'Fishing for spot prawns in British Columbia began in the early 1900s, with the first commercial fishery opening in the 1920s. Initially, the fishery was small-scale and localized, but as demand for spot prawns grew, the fishery expanded. In the 1950s, the use of traps became the primary method of catching spot prawns in British Columbia.',
                    'It is important to note that the fishing of spot prawns has been a traditional activity for many Indigenous peoples living along the Pacific Coast for thousands of years. The traditional method of fishing for spot prawns involved the use of woven baskets, dip-nets and spears, rather than traps. The spot prawns were an important food source for Indigenous communities and continue to hold cultural significance today.',
                    'The use of traps is a sustainable fishing method because it allows for selective harvesting and does not cause damage to the seafloor. Spot prawn traps are baited with fish or squid and are set in specific areas to target the prawns. Traps are designed to allow undersized and non-target species to escape, minimizing bycatch.',
                    'In the late 1970s, J.O. Thomas and Associates started to get involved in the spot prawn industry and since then, it has been a leader in promoting and implementing sustainable fishing practices for spot prawns. The company works closely with scientists, regulators, and other stakeholders to ensure the fishery is well-managed and sustainable.',
                    'Today, the spot prawn fishery in British Columbia is considered one of the most well-managed and sustainable in the world. At Fisherman’s Market, we are proud to source our spot prawns from local, sustainable fisheries that follow responsible fishing practices promoted by J.O. Thomas and Associates. We encourage our customers to support sustainable fishing by choosing spot prawns from British Columbia.'
                ]
            }
        ],
        imgUrl: '/images/blog/FishermansMarket_BC_Commercial_Fishing_Week_sustainable_fishing_spot_prawns-scaled.webp'
    },
    {
        id: 4,
        title: 'BCCFW: Sustainably caught halibut',
        author: 'Fisherman\'s Market',
        date: 'January 30, 2023',
        type: ['BC Commercial Fishing Week'],
        abstract: 'British Columbia is home to some of the most well-managed halibut fisheries in the world, and we are proud to source our halibut from local, sustainable fisheries.',
        main: [
            {
                id: 1,
                subTitle: '',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_sustainable_fishing_halibut_GoodFish.webp',
                list: [
                    'At Fisherman’s Market, we are committed to providing our customers with the highest quality, sustainably caught seafood. During this year’s BC Commercial Fishing Week (BCCFW), we want to take the opportunity to highlight one of our most popular and sustainably caught species: halibut.',
                    'Halibut is a species found in the Pacific Ocean and is often caught using longline fishing. Longline fishing is a sustainable fishing method because it allows for selective harvesting and has low bycatch rates. This means that only the desired size and species of fish are caught, minimizing the impact on the ecosystem and other marine life.',
                    'Halibut is known for its delicate flavour and firm, meaty texture. It is often prepared in similar ways as swordfish, like grilling, baking, or pan-frying. It is also a good source of protein and omega-3 fatty acids, making it a healthy and delicious choice for customers.',
                    'British Columbia is home to some of the most well-managed halibut fisheries in the world, and we are proud to source our halibut from local, sustainable fisheries. The halibut populations are healthy and well managed by regional fishery management organizations, ensuring that the fish are caught in a responsible manner.',
                    'At Fisherman’s Market, we believe that sustainability is not only an environmental responsibility, but also an economic and social one. It is our duty to ensure that the seafood we provide to our customers is not only delicious and high quality, but also responsibly and sustainably sourced. We hope that our customers will join us in celebrating BCCFW and learning more about the importance of sustainable fishing in British Columbia.',
                    'We invite you to try some of our delicious halibut during BCCFW and experience the taste of sustainably caught seafood. If you have any questions about our sustainable fishing practices or the halibut we offer, please do not hesitate to ask us. We at Fisherman’s Market are committed to bringing you the best sustainably caught seafood in BC.'
                ]
            },
            {
                id: 2,
                subTitle: 'Halibut: A history of sustainable fishing in BC',
                imgUrl: '/images/blog/FishermansMarket_BCCFW_sustainable_fishing_halibut_Kenny.webp',
                list: [
                    'Halibut, also known as Hippoglossus stenolepis, is a popular species found in the Pacific Ocean and is a staple in the British Columbia seafood industry. The halibut has a long history of sustainable fishing in British Columbia, dating back to the early 20th century.',
                    'Fishing for halibut in British Columbia began in the early 1900s, with the first commercial fishery opening in the 1920s. Initially, the fishery was small-scale and localized, but as demand for halibut grew, the fishery expanded. In the 1950s, the use of longlines became the primary method of catching halibut in British Columbia.',
                    'It is important to note that the fishing of halibut has been a traditional activity for many Indigenous peoples living along the Pacific Coast for thousands of years. The traditional method of fishing for halibut involved the use of woven baskets, dip-nets and spears, rather than longlines. The halibut was an important food source for Indigenous communities and continues to hold cultural significance today.',
                    'The use of longlines is considered a sustainable fishing method because it allows for selective harvesting and does not cause damage to the seafloor. Longlines are baited hooks set in specific areas to target halibut. The hooks are designed to allow undersized and non-target species to escape, minimizing bycatch.',
                    'In 1923, the International Pacific Halibut Commission (IPHC) was formed as a treaty between Canada and the United States, to manage the halibut fishery in the Pacific Ocean. The IPHC sets quotas, regulates the season, and manages the fishery to ensure sustainability. Efforts are also being made to reduce bycatch and promote selective harvesting methods that minimize the impact on non-target species.',
                    'Today, the halibut fishery in British Columbia is considered to be one of the most well-managed and sustainable in the world. The IPHC ensures that the halibut population is fished at sustainable levels and that the fishing practices are environmentally friendly. The IPHC was the first bill passed without Royal consent, this was a significant step in the management of the halibut fishery. At Fisherman’s Market, we are proud to source our halibut from local, sustainable fisheries that follow responsible fishing practices set by the IPHC. We encourage our customers to support sustainable fishing by choosing halibut from British Columbia.'
                ]
            }
        ],
        imgUrl: '/images/blog/FishermansMarket_BC_Commercial_Fishing_Week_sustainable_fishing_halibut-scaled.webp'
    }

];

export default blogData;