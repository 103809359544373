import React, { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import axios from "axios";
import { Link } from 'react-router-dom';

import '../css/FavoriteProducts.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const FavoriteProducts = () => {
    const [favoriteData, setFavoriteData] = useState([]);

    const apiUrl = 'https://admin.thefishermansmarket.ca/wp-json/wc/';
    const consumerKey = process.env.REACT_APP_API_KEY;
    const consumerSecret = process.env.REACT_APP_API_SECRET;

    const axiosProductsInstance = axios.create({
        baseURL: apiUrl,
        auth: {
            username: consumerKey,
            password: consumerSecret,
        },
        params: {
            // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
        }
    });

    const fetchData = useCallback(() => {
        axiosProductsInstance.get('v3/products?per_page=100').then(res => {
            setFavoriteData(res.data.filter(product => product.tags.some(tag => tag.name === "Favorite")));
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData])


    return (
        <div className="favorite-products">
            <div className="favorite-products-wrapper">
                <div className='favorite-products-title'>
                    <p className="favorite-titel">Top 10 Items In BC Now</p>
                    {/* <p className="favorite-subtitle">Our most popular seafood</p> */}
                </div>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={20}
                    // loop={true}
                    centeredSlides={true}
                    centerInsufficientSlides={true}
                    centeredSlidesBounds={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    className="mySwiper"
                >
                    {favoriteData.map((product, index) => (
                        <SwiperSlide key={index} className="product">
                            <div className='favorite-image-wrapper'>
                                <div className='favorite-image' style={{ backgroundImage: 'url(' + product.images[0].src + ')' }}></div>
                                <Link to={`/product/${product.id}`} className="shop-now-fav">SHOP NOW</Link>
                            </div>
                            <div className="product-info">
                                <h3>{product.name}</h3>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default FavoriteProducts;
