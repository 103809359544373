import { useEffect } from 'react';
import { storeUtmParameters } from '../utils/utmUtils';

const useCaptureUtm = (options = {}) => {
  useEffect(() => {
    try {
      storeUtmParameters(options);
    } catch (error) {
      console.error('Failed to store UTM parameters:', error);
      // Optionally, handle the error based on your application's needs
    }
  }, []); // Dependencies array remains empty to ensure this effect runs once on mount
};

export default useCaptureUtm;