import React from 'react';
import '../css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import mailChimp from '../images/WebsiteFooter_mailchimp.png';
import googleReview from '../images/WebsiteFooter_google.png'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer-item">
          <p>Explore</p>
          <Link to="shop">Shop Online</Link>
          <Link to="locations">Store Locations</Link>
          <Link to="recipes">Recipes</Link>
          <Link to="blog">Blogs</Link>
        </div>

        <div className="footer-item">
          <p>Learn About Us</p>
          <Link to="contact-us">Contact Us</Link>
          <Link to="about-us">About Us</Link>
          <Link to="shipping">Shipping & Delivery</Link>
          <Link to="terms-conditions">Terms & Conditions</Link>
          <Link to="privacy-policy">Privacy Policy</Link>
        </div>

        <div className='footer-item'>
          <p>Follow Us</p>
          <div className="social-icons">
            <a href="https://www.facebook.com/fishermansmarket"><FontAwesomeIcon icon={faFacebook} /></a>
            <a href="https://www.instagram.com/fishermansmarket/?fbclid=IwAR2CJsxIW1teKg99qB3-NwI8rDGRb2CaO6NHU4X1TkpR7hpabAa5vLueAwo"><FontAwesomeIcon icon={faInstagram} /></a>
            <a href="https://twitter.com/fmseafood"><FontAwesomeIcon icon={faTwitter} /></a>
            <a href="https://www.pinterest.ca/fmseafoods/"><FontAwesomeIcon icon={faPinterest} /></a>
          </div>
        </div>

        <div className='footer-item'>
          <p><img src={googleReview} width="140px"></img></p>
          <p><img src={mailChimp} width="150px"></img></p>
        </div>

      </div>
    </div>

  );
};

export default Footer;