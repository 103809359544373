import React from 'react';
import policyData from '../data/policyData';
import SignUpButton from '../components/SignUpButtom';
import '../css/Terms.css';


export default function Policy() {

    return (
        <div className="terms">

            <div className="terms-banner">
                <h2>Privacy Policy</h2>
            </div>

            <div className="terms-wrapper" style={{marginTop: "30px"}}>
                <div className="terms-body">
                    <div className="terms-body-section">
                        <h3>{policyData.title}</h3>
                        {policyData.content.map((section, index) => {
                            return (
                                <div key={index}>
                                    <h4>{section.subtitle}</h4>
                                    {section.list ?
                                        <ul>
                                            {section.content.map((passage, index) => {
                                                return (
                                                    <li key={index} dangerouslySetInnerHTML={{__html:passage}}></li>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <p>{section.content.map((passage, index) => {
                                            return (
                                                <p key={index} dangerouslySetInnerHTML={{__html:passage}}></p>
                                            )
                                        })}</p>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <SignUpButton />
        </div>
    )
}