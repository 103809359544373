import React, { useEffect, useState } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../../images/FMS_horizontal-logo-RGB-white.svg';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faGoogle } from '@fortawesome/free-brands-svg-icons'
import SignUpButton from '../../components/SignUpButtom';
import { useUser } from '../../context/UserContext';

import toast, { Toaster } from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";

import "../../css/SignIn.css"
// import toast, { Toaster } from 'react-hot-toast';

const SignIn = () => {
  const { authenticate, isLoggedIn } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleSignIn = async (event) => {
    event.preventDefault();
    if (password === "" || email === "") {
      alert('Please fill in all fields');
      return;
    }
    authenticate.mutate({ email: email, password: password })

  };

  const loginSuccess = () => toast('LoggedIn Successfully.');

  useEffect(() => {
    console.log('Sign In Effect Called')

    if (isLoggedIn) {

      navigate("/")
    }
  }, [isLoggedIn]);

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(event.target.value);
    const input = event.target;
    if (input.value !== "") {
      input.classList.add("filled");
      if (!isValidEmail) {
        // Handle invalid email format
        input.classList.add("error");
        setIsValidEmail(false);
      } else {
        input.classList.remove("error");
        setIsValidEmail(true);
      }
    } else {
      input.classList.remove("filled");
      input.classList.remove("error");
      setIsValidEmail(true);
    }
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (event.target.value !== "") {
      event.target.classList.add("filled");
    } else {
      event.target.classList.remove("filled");
    }
  };

  useEffect(() => {
    if (authenticate.isError) {
      toast.error('Invalid account or password.')
    }
  }, [authenticate.isError]);
  useEffect(() => {
    if (authenticate.isError) {
      setTimeout(() => {
        authenticate.reset()
      }, 3000)
    }
  }, [authenticate.isError]);

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="form-content">

          <div className="form-content-title">Login To Your Account</div>
          {/* <div className="social-media-container">
            <div className="social-media-button"><FontAwesomeIcon icon={faFacebook} /></div>
            <div className="social-media-button"><FontAwesomeIcon icon={faTwitter} /></div>
            <div className="social-media-button"><FontAwesomeIcon icon={faLinkedin} /></div>
          </div> */}
          <div className="divider"><hr />
            {/* <span>OR</span> */}
            <hr /></div>
          <form action="#" className="form-content-body">
            <div className="form-group">
              <input className="form-input" required={true} type="email" value={email} onChange={handleEmailChange} />
              <label className="form-label" htmlFor="email">{/* <FontAwesomeIcon icon={faUser} /> */} Email</label>
              {!isValidEmail && <div className="error-message">Invalid Email Address</div>}
            </div>
            <div className="form-group">
              <input className="form-input" required={true} type="password" value={password} onChange={handlePasswordChange} />
              <label className="form-label" htmlFor="password">{/* <FontAwesomeIcon icon={faLock} /> */} Password</label>
            </div>
            <div className="form-group">
              <button className="form-button" onClick={handleSignIn} >
                {authenticate.isPending ? <span><ClipLoader size={10} color='#FFF' data-testid="loader" /></span> : "SIGN IN"}
              </button>
            </div>
          </form>
          <div className="form-group" style={{ flexDirection: "row" }}>
            <Link to="/customer/forget" className="form-link" href="#">Forgot Password</Link>
            <NavLink className="form-link" to="/customer/register">Register</NavLink>
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-center"
        toastOptions={{
          error: {
            duration: 5000,
          }
        }}
        reverseOrder={false} />
    </div>
  );
};

export default SignIn;
