import React from 'react';
import termsData from '../data/termsData';
import SignUpButton from '../components/SignUpButtom';
import bannerImg from '../images/071830063-traditional-sustainable-oyster.webp';
import '../css/Terms.css';


export default function History() {

    return (
        <div className="terms">

            <div className="terms-banner" style={{backgroundImage: 'url(' + bannerImg + ')' }}>
                <h2>The Fisherman’s Market history</h2>
            </div>

            <div className="terms-wrapper" style={{marginTop: "30px"}}>
                <div className="terms-body">
                    <div className="terms-body-section">
                        <p>Raised in Comox, BC, Geoff Austin and Adam Pearl have been fishing together since their teens, first on Adam’s father’s boat and later on fishing vessels they captained. Throughout their teens and twenties, the two young men shared a strong work ethic, accommodation, travel, and fishing expeditions.</p>
                        <p>Despite a shipwreck that changed their careers, the North Pacific Ocean still draws the pair. Once a fisherman, always a fisherman.</p>
                        <h2>Shipwrecked</h2>
                        <p>On their way home from prawn season in July 2002, Geoff and Adam hit a riptide outside Kelsey Bay on the north coast of Vancouver Island. The fifty-three-foot long-liner, Fritzi Ann, capsized and sank. Having to swim out of an overturned vessel in frigid waters left a lasting impression. Already contemplating a lifestyle and professional change, they embarked on a new adventure after this calamitous event.</p>
                        <h2>The fishermen move inland</h2>
                        <p>Crossing Canada two months later, while searching for the perfect location to open a fish shop, the pair stopped in Nelson, BC. The progressive mountain community of 10,000 people nestled on the shores of Kootenay Lake enticed the fishermen inland. They dropped anchor and signed a lease for the space their business still inhabits. By spring the following year, Fisherman’s Market opened its doors.</p>
                        <h2>Fisherman’s Market expansion</h2>
                        <p>Geoff and Adam channelled their love of fishing into procuring the most sustainably caught and delicious seafood for their clients. Never afraid to leap into deep waters, they also opened a small restaurant and the fish market with only washing dishes in a ship’s galley, acting as industry experience between them.</p>
                        <p>They continued to fish to make ends meet and to stay connected to the source of their business. In 2005, Fisherman’s Market expanded to include the Open Range, a butcher shop. In 2008, they moved the butcher shop into the restaurant area to make way for the Dock, a classic fish and chips shop.</p>
                        <h2>Wholesaling and partnering</h2>
                        <p>By 2011 they started wholesaling seafood to restaurants and stepped away from working in the shop to concentrate on their businesses. A year later, one of their seafood supplier representatives, Cory Siegner, partnered with them to open and run another Fisherman’s Market in Kelowna. While Geoff and Adam were ready for the expansion, Cory facilitated that big next step.</p>
                        <p>Growing up in the West Coast fishing world, Cory’s involvement in harvesting, plant operations and sales from his teens onward made him a natural fit for taking the lead in marketing and advertising at Fisherman’s Market, a role he continues to build upon today.</p>
                        <h2>The team continues to grow</h2>
                        <p>Looking to expand again, in 2014, the three men opened a shop in Kamloops. Gina Fedora, a friend of Cory’s from when he worked selling fish on Granville Island, had just moved to Kamloops. She was the perfect manager for the store.</p>
                        <p>A fourth shop opened in 2015 in West Vancouver with Katie Budd, an ex-commercial spot prawn fisherwoman, at the helm. She later became part owner with the three other men in that location and a new Fisherman’s Market in Gibsons, BC, on the Sunshine Coast in 2017.</p>
                        <p>Jeremy Wheeler joined the crew in 2009 in the original shop. Over the years has filled many roles, including a fishmonger, shop assistant and manager, accountant, website builder and right-hand man to Geoff and Adam.</p>
                        <h2>Teamwork, tenacity and a sense of adventure</h2>
                        <p>The fishermen continue to navigate a course that provides the best quality seafood for their customers and an excellent working experience for their employees. A sense of teamwork prevails in all their shops and ongoing endeavours.</p>
                        <p>Geoff and Adam still fish, continue to live in Nelson with their families and share their passion with their kids. Fisherman’s Market is built on hard work, integrity and a sense of adventure. Geoff, Adam, Cory and Katie hope you enjoy a taste of this in every bite of your seafood.</p>
                    </div>
                </div>
            </div>
            <SignUpButton />
        </div>
    )
}