import React, { useEffect, useState } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox, faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import logoImage from '../../images/FMS_horizontal-logo-RGB-white.svg';
import { useUser } from '../../context/UserContext';
import SignUpButton from '../../components/SignUpButtom';
import { axiosOrderInstance, axiosMailChimpInstance } from '../../mutations/checkout'

import axios from 'axios';

import toast, { Toaster } from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";
import "../../css/SignIn.css"

const notify = (value) => toast(`${value}`);


const Register = () => {

  const navigate = useNavigate();
  const { isLoggedIn, registerUser } = useUser();

  // https://fm:8890/?rest_route=/simple-jwt-login/v1/users&email=fearow@gmail.com&password=123456abc&AUTH_KEY=Vmk0taqAsXMrYN&last_name=Babish&first_name=Farish
  // URL EXAMPLE::
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(event.target.value);
    const input = event.target;
    if (input.value !== "") {
      input.classList.add("filled");
      if (!isValidEmail) {
        // Handle invalid email format
        input.classList.add("error");
        setIsValidEmail(false);
      } else {
        input.classList.remove("error");
        setIsValidEmail(true);
      }
    } else {
      input.classList.remove("filled");
      input.classList.remove("error");
      setIsValidEmail(true);
    }
  };
  const handleFirstNameChange = (event) => {
    event.preventDefault();
    setFirstName(event.target.value);
    if (event.target.value !== "") {
      event.target.classList.add("filled");
    } else {
      event.target.classList.remove("filled");
    }
  };
  const handleLastNameChange = (event) => {
    event.preventDefault();
    setLastName(event.target.value);
    if (event.target.value !== "") {
      event.target.classList.add("filled");
    } else {
      event.target.classList.remove("filled");
    }
  };
  const handlePasswordChange = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
    if (event.target.value !== "") {
      event.target.classList.add("filled");
    } else {
      event.target.classList.remove("filled");
    }
  };

  const handleRegistration = (event) => {
    event.preventDefault();
    console.log("Registering.....")
    if (password === "" || email === "" || firstName === "" || lastName === "") {
      alert('Please fill in all fields');
      return;
    }
    if (!termsAccepted) {
      alert('You must accept the terms and conditions');
      return;
    }
    registerUser(firstName, lastName, email, password)
      .then(() => {
        registerGuestToMailChimp();
      })
      .catch(error => {
        console.log('User registration failed:', error);
      });
  };

  const registerGuestToMailChimp = async () => {
    console.log("data before subscribe: ", firstName + lastName, email)
    const data = {
      'email': email,
      'name': firstName + ' ' + lastName,
      'termsAccepted': true,
    }
    try {
      const res = await axiosMailChimpInstance.post('/mailchimp/register', data);
      if (res.status === 200) {
        console.log('Thank you for subscribing to our newsletter.');
      } else if (res.status === 400 && res.data.code === 'mailchimp_permanently_deleted') {
        // user has been permenantly deleted, have to use the MailChimp portal to re-subscribe
        window.location.href = 'http://eepurl.com/haDB35';
      } else {
        console.log('Failed to subscribe.');
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error: ", error, "error.response: ", error.response)

        if (error.response.status === 400 && error.response.data.code === 'mailchimp_permanently_deleted') {
          window.location.href = 'http://eepurl.com/haDB35';
        } else {
          console.log('Failed to subscribe: ' + (error.response.data.message || 'Unknown error'));
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error during registration:", error);
        console.log('An error occurred. Please try again later.');
      }
    }
  }

  useEffect(() => {
    console.log('Registration Effect Called')
    if (isLoggedIn) {
      navigate('/customer/account')
    }
  }, [isLoggedIn]);


  return (
    <div className="form-container">
      <div className="form-wrapper">
        <div className="form-content">
          <div className="form-content-title">Create an Account</div>
          {/* <div className="social-media-container">
            <div className="social-media-button"><FontAwesomeIcon icon={faFacebook} /></div>
            <div className="social-media-button"><FontAwesomeIcon icon={faTwitter} /></div>
            <div className="social-media-button"><FontAwesomeIcon icon={faInstagram} /></div>
            <div className="social-media-button"><FontAwesomeIcon icon={faLinkedin} /></div>
          </div> */}

          <div className="divider"><hr />
            {/* <span>OR</span> */}
            <hr /></div>

          <form action="#" className="form-content-body">

            <div className="form-group">
              <input className="form-input" required={true} type="text" value={firstName} onChange={handleFirstNameChange} />
              <label className='form-label' htmlFor="firstname">{/* <FontAwesomeIcon icon={faUser} />  */}First Name</label>
            </div>
            <div className="form-group">
              <input className="form-input" required={true} type="text" value={lastName} onChange={handleLastNameChange} />
              <label className='form-label' htmlFor="lastname">{/* <FontAwesomeIcon icon={faUser} />  */}Last Name</label>
            </div>

            <div className="form-group">
              <input className="form-input" required={true} type="email" value={email} onChange={handleEmailChange} />
              <label className='form-label' htmlFor="email">{/* <FontAwesomeIcon icon={faInbox} />  */}Email</label>
              {!isValidEmail && <div className="error-message">Invalid Email Address</div>}
            </div>
            <div className="form-group">
              <input className="form-input" required={true} type="password" value={password} onChange={handlePasswordChange} />
              <label className='form-label' htmlFor="password">{/* <FontAwesomeIcon icon={faLock} />  */}Password</label>
            </div>

            <div className="form-group">
              <div style={{ margin: "0 0 15px", display: "flex", gap: "6px", alignItems: "flex-start", fontSize: "14px" }}>
                <input type="checkbox" required={true} checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />
                <span>I agree to the <Link to="/terms-conditions" target="_blank" rel="noopener noreferrer" style={{ color: "#4c7ebc", fontWeight: "500" }}>Terms and Conditions</Link></span>
              </div>
              <button className="form-button" onClick={handleRegistration}>CREATE ACCOUNT</button>
            </div>
          </form>
          <div className="form-group">
            <NavLink className="form-link" to="/customer/sign-in">Sign In</NavLink>
          </div>
        </div>
      </div>
      {/* <Toaster />
      <SignUpButton /> */}
    </div>

  );
};

export default Register;
