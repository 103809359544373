import axios from "axios"

const apiUrl = 'https://admin.thefishermansmarket.ca/wp-json/wc/';
const consumerKey = process.env.REACT_APP_API_KEY;
const consumerSecret = process.env.REACT_APP_API_SECRET;

export const axiosUserInstance = axios.create({
    baseURL: apiUrl,
    auth: {
        username: consumerKey,
        password: consumerSecret,
    },
    params: {
        // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
    }
});
export const axiosUserDetailsInstance = axios.create({
    baseURL: apiUrl,
    auth: {
        username: consumerKey,
        password: consumerSecret,
    },
    params: { // Specify only these fields in the response
    }
});