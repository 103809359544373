import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';

import "../css/SideBar.css"
// Shopping Cart
import SideBarProductItem from './sidebar/SideBarProductItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCheckCircle, faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { useCart } from '../context/CartContext';
// import { useSideBar } from '../context/SidebarContext';

const SideBar = ({ isVisible, toggleSideBar }) => {
    // Properties
    const { cartItems, getTotalItems, calculateTotalCartPrice } = useCart();
    const sideBarRef = useRef(null);

    // Attach click event listener when the component mounts
    return (
        <div className="sidebar-container">
            <div className={`sidebar ${isVisible ? 'open' : 'close'}`} ref={sideBarRef}>
                <div className='sidebar-cart-header'>
                    <span><FontAwesomeIcon icon={faCheckCircle} />&nbsp;&nbsp;Added To Cart</span>
                    <div className='back-to-shop-button'><a onClick={toggleSideBar}><FontAwesomeIcon icon={faClose} /></a></div>
                </div>
                <div className='sidebar-wrapper'>
                    <div className='sidebar-product-list'>
                        {cartItems.length !== 0 && <SideBarProductItem item={cartItems[cartItems.length - 1]} />}
                    </div>
                    <div className='sidebar-actions-container '>
                        <div className='sidebar-cart-total-container'>
                            <div style={{fontWeight: 400}}>Subtotal</div>
                            <div>CA$ {calculateTotalCartPrice()} </div>
                        </div>

                        <div className='sidebar-actions'>
                            <Link className='products-list-cart' to="/cart" >VIEW CART ( {getTotalItems()} )</Link>
                            <Link className='products-list-check' to="/checkout" >CHECK OUT</Link>
                        </div>
                    </div>
                </div>
            </div >
            {/* {isVisible && <div className="backdrop" onClick={toggleSideBar} />} */}
        </div>
    );
};

export default SideBar;
