import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';

const ForgetPassword = () => {
    const [requestEmail, setRequestEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleSubmit = async (event) => {
        if (requestEmail === "") {
            alert('Please fill in all fields');
            return;
        }
        event.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('https://admin.thefishermansmarket.ca/wp-json/simple-jwt-login/v1/user/reset_password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: requestEmail,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to request password reset');
            }
            toast.success('Password reset link sent to your email.');
        } catch (error) {
            toast.error("Failed to send password reset link.");
        } finally {
            setLoading(false);
        }
    };
    const handleEmailChange = (event) => {
        event.preventDefault();
        setRequestEmail(event.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(event.target.value);
        const input = event.target;
        if (input.value !== "") {
            input.classList.add("filled");
            if (!isValidEmail) {
                // Handle invalid email format
                input.classList.add("error");
                setIsValidEmail(false);
            } else {
                input.classList.remove("error");
                setIsValidEmail(true);
            }
        } else {
            input.classList.remove("filled");
            input.classList.remove("error");
            setIsValidEmail(true);
        }
    };

    return (
        <div className="form-container">
            <div className="form-wrapper">
                <div className="form-content">

                    <div className="form-content-title">Please Enter Your Email Address</div>
                    <form onSubmit={handleSubmit} className="form-content-body">
                        <div className="form-group">
                            <input
                                type="email"
                                value={requestEmail}
                                onChange={handleEmailChange}
                                className="form-input" required={true}
                            />
                            <label className="form-label" htmlFor="email">{/* <FontAwesomeIcon icon={faUser} /> */} Email</label>
                            {!isValidEmail && <div className="error-message">Invalid Email Address</div>}
                        </div>
                        <button type="submit" disabled={loading} className="form-button">
                            {loading ? 'Sending...' : 'SEND RESET LINK'}
                        </button>
                    </form>
                    <div className="form-group">
                        <NavLink className="form-link" to="/customer/sign-in">Sign In</NavLink>
                    </div>
                </div>
            </div>
            <Toaster
                position="bottom-center"
                toastOptions={{
                    error: {
                        duration: 5000,
                    }
                }}
                reverseOrder={false} />
        </div>
    );
};

export default ForgetPassword;
