import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from '../images/FMS_horizontal-logo-RGB-white.svg';
import '../css/FeatureBanner.css';

const FeatureBanner = ({ title, productName, description, desWidth, bgImage, location, linkTo, buttonText }) => {
    // include css in this component

    return (
        <div className="feature-banner" style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="feature-banner-wrapper" style={{justifyContent: location}}>
                <div className="feature-banner-content">
                    <img className="feature-banner-logo" src={logoImage}></img>
                    <h1 className="feature-banner-title">{title}</h1>
                    <h2 className="feature-banner-product-name">{productName}</h2>
                    <p className="feature-banner-description" style={{maxWidth: `${desWidth}`}}>{description}</p>
                    <div className="feature-banner-button-content">
                        <Link to={`/${linkTo}`} className="feature-banner-button">{buttonText}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeatureBanner;