import React, { useState, useEffect, useCallback } from 'react';
import showSearch from './Navbar';
import axios from "axios";
import { Link } from 'react-router-dom';

const SearchResult = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchButtonStyle, setSearchButtonStyle] = useState('search-button');

    const apiUrl = 'https://admin.thefishermansmarket.ca/wp-json/wc/';
    const consumerKey = process.env.REACT_APP_API_KEY;
    const consumerSecret = process.env.REACT_APP_API_SECRET;

    // Axios configuration
    const axiosProductsInstance = axios.create({
        baseURL: apiUrl,
        auth: {
            username: consumerKey,
            password: consumerSecret,
        },
        params: {
            // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
        }
    });

    const fetchData = useCallback((searchQuery) => {
        setLoading(true);
        console.log(searchQuery);
        axiosProductsInstance.get(`v3/products?search=${searchQuery}`).then(res => {
            setSearchResults(res.data);
            setLoading(false);
            console.log(res.data);
        });
    }, [searchQuery]);

    const handleSearchResultClick = () => {
        // Invoke the callback function passed from Navbar.js
        showSearch(false);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        
        return function(...args) {
          const context = this;
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
      
          timeoutId = setTimeout(() => {
            func.apply(context, args);
          }, delay);
        };
    }

    const debounceSearch = useCallback(debounce((query) => {
        if (query.trim() === '') {
            setSearchResults([]);
            setLoading(false);
            return;
        }
        fetchData(query);
    }, 300), [fetchData]);

    const handleInputChange = (e) => {
        debounceSearch(e.target.value);
    }

    return (
        <div className='search-bar-input'>
            <input type="text" placeholder="Search for products" onChange={handleInputChange}/>
            {loading && <div className="loading"></div>}
            <div className={searchResults.length === 0 ? "search-results hide" : "search-results"}>
                {searchResults.map((result) => (
                    <Link key={result.id} to={`/product/${result.id}`} onClick={handleSearchResultClick} className="result-list">
                        <div className='result-image' style={{ backgroundImage: `url(${result.images[0].src})` }} />
                        <div className='result-info'>
                            <h3>{result.name}</h3>
                            <div>
                                <span className="price">
                                    ${result.price}
                                    <span className="price-uom">{result.meta_data?.find(meta => meta.key === "unit_of_sale").value}</span>
                                </span>
                                {result.on_sale &&
                                    <span className="regular-price">
                                        ${result.regular_price}
                                        <span className="price-uom">{result.meta_data?.find(meta => meta.key === "unit_of_sale").value}</span>
                                    </span>
                                }
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default SearchResult;