import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUtmParameters } from '../utils/utmUtils';

const UtmContext = createContext();

export const useUtm = () => useContext(UtmContext);

export const UtmProvider = ({ children }) => {
  const [utmParams, setUtmParams] = useState(() => {
    const savedParams = localStorage.getItem('utmParams');
    return savedParams ? JSON.parse(savedParams) : {};
  });

  useEffect(() => {
    console.log('UtmProvider useEffect');
    const utmParams = getUtmParameters();
    setUtmParams(utmParams);
    localStorage.setItem('utmParams', JSON.stringify(utmParams));
  }, []);

  return (
    <UtmContext.Provider value={{ utmParams }}>
      {children}
    </UtmContext.Provider>
  );
};