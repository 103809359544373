import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import blogData from '../data/blogData';
import '../css/BlogDetail.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons';

import Recommends from './Recommends';


export default function BlogDetail() {
    const [blog, setBlog] = useState(null);
    const [blogPre, setBlogPre] = useState(null);
    const [blogNxt, setBlogNxt] = useState(null);
    const { blogId } = useParams();

    useEffect(() => {

        function getBlogById(id) {
            const parseId = parseInt(id, 10);
            const blog = blogData.find(blog => blog.id === parseId);
            const blogPre = blogData.find(blog => blog.id === parseId - 1);
            const blogNxt = blogData.find(blog => blog.id === parseId + 1);
            return {
                preObj: blogPre,
                currentObj: blog,
                nexObj: blogNxt
            };
        }

        const fetchedBlog = getBlogById(blogId);
        if (fetchedBlog.currentObj) {
            setBlog(fetchedBlog.currentObj);
        } else {
            setBlog(null);
        }
        if (fetchedBlog.preObj) {
            setBlogPre(fetchedBlog.preObj);
        } else {
            setBlogPre(null);
        }
        if (fetchedBlog.nexObj) {
            setBlogNxt(fetchedBlog.nexObj);
        } else {
            setBlogNxt(null);
        }

        window.scrollTo(0, 0);
    }, [blogId]);

    if (!blog) {
        return <p>Blog not found.</p>;
    }

    return (
        <div className="blog-detail">
            <div className="blog-detail-wrapper">
                <div className="blog-detail-info">
                    <h2 className="blog-detail-title">{blog.title}</h2>
                    <div className="blog-detail-grid">
                        <div className="detail-grid-item">
                            <p>{blog.author}</p>
                        </div>
                        <div className="detail-grid-item">
                            <p>{blog.date}</p>
                        </div>
                        <div className="detail-grid-item">
                            <div className="detail-social-icons">
                                <a href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                                <a href={`https://twitter.com/share?url=${window.location.href}&text=${blog.title}`} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                                <a href={`https://pinterest.com/pin/create/bookmarklet/?url=${window.location.href}&description=${blog.title}`} target="_blank"><FontAwesomeIcon icon={faPinterest} /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-detail-image">
                    <div className="detail-image" style={{ backgroundImage: 'url(' + blog.imgUrl + ')' }}>
                    </div>
                </div>
                <div className="blog-content-abs">
                    <p>{blog.abstract}</p>
                </div>
                <div className="blog-content-body">
                    {blog.main.map((item, index) => {
                        return (
                            <div key={index} className="content-body-section">
                                {item.subTitle && <h3>{item.subTitle}</h3>}
                                {item.list.map((list, indexX) => {
                                    return (
                                        <p key={indexX}>{list}</p>
                                    )
                                })}
                                {item.imgUrl && <img src={item.imgUrl} />}
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="blog-pageturn">
                {blogId > 1 && <a href={`/blog/${blogId - 1}`} className="turn-button-left">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Previous Article
                    <span>{blogPre.title}</span>
                </a>}
                {blogId < blogData.length && <a href={`/blog/${parseInt(blogId, 10) + 1}`} className="turn-button-right">
                    Next Article
                    <FontAwesomeIcon icon={faChevronRight} />
                    <span>{blogNxt.title}</span>
                </a>}
            </div>
            <Recommends />
        </div>
    )
}