import React, { useState, useEffect } from 'react';
import recipeData from './data/recipeData';
import { Link } from 'react-router-dom';
import './css/RecipeList.css';
import Recommends from './components/Recommends';
import SignUpButton from './components/SignUpButtom';
import PopUp from './components/PopUp';

const RecipeList = () => {
    const [recipes, setRecipes] = useState(recipeData);
    const [filteredRecipes, setFilteredRecipes] = useState(recipeData);
    const [selectedType, setSelectedType] = useState('');
    const [selectedPrepTime, setSelectedPrepTime] = useState('');
    const [selectedTotalTime, setSelectedTotalTime] = useState('');
    const [productsPerPage] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);

    // Extract unique values for "Type", "Prep Time", and "Total Time"
    const types = [...new Set(recipeData.flatMap(recipe => recipe.type))];
    const prepTimes = [...new Set(recipeData.map(recipe => recipe.prepTime))];
    const totalTimes = [...new Set(recipeData.map(recipe => recipe.prepTimeValue + recipe.cookTimeValue))].sort((a, b) => a - b);

    useEffect(() => {
        let filtered = recipeData;

        if (selectedType) {
            filtered = filtered.filter(recipe => recipe.type.includes(selectedType));
        }

        if (selectedPrepTime) {
            filtered = filtered.filter(recipe => recipe.prepTime === selectedPrepTime);
        }

        if (selectedTotalTime) {
            filtered = filtered.filter(recipe => (recipe.prepTimeValue + recipe.cookTimeValue) === parseInt(selectedTotalTime));
        }

        setFilteredRecipes(filtered);
    }, [selectedType, selectedPrepTime, selectedTotalTime]);

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        setSelectedPrepTime('');
        setSelectedTotalTime('');
    }
    const handlePrepTimeChange = (e) => {
        setSelectedType('');
        setSelectedPrepTime(e.target.value);
        setSelectedTotalTime('');
    };
    const handleTotalTimeChange = (e) => {
        setSelectedType('');
        setSelectedPrepTime('');
        setSelectedTotalTime(e.target.value)
    };

    const handleReset = () => {
        setSelectedType('');
        setSelectedPrepTime('');
        setSelectedTotalTime('');
        setFilteredRecipes(recipeData);
    };

    // Pagination logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredRecipes.slice(indexOfFirstProduct, indexOfLastProduct);

    const RecipeDataList = (props) => {
        if (currentProducts.length === 0) {
            return ("No recipe found");
        }
        return (
            currentProducts.map((recipe, index) => (
                <Link to={`/recipes/${recipe.id}`} key={index} className="recipe-container-list">
                    <div className="recipe-image-wrapper">
                        <div className="recipe-image" style={{ backgroundImage: 'url(' + recipe.imgUrl + ')' }} ></div>
                    </div>
                    <div className="recipe-info">
                        <div className="recipe-info-title">{recipe.title}</div>
                    </div>
                    <a href="#">Read Now
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </a>
                </Link>
            ))
        );
    }

    // Change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0); // Scroll to top
    };

    const Pagination = ({ productsPerPage, totalProducts, currentPage, paginate }) => {
        const pageNumbers = [];

        for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
            pageNumbers.push(i);
        }

        return (
            <nav>
                <ul className='pagination'>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className='page-link' onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                    </li>
                    {pageNumbers.map(number => (
                        <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => paginate(number)}>{number}</button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                        <button className='page-link' onClick={() => paginate(currentPage + 1)} disabled={currentPage === pageNumbers.length}>Next</button>
                    </li>
                </ul>
            </nav>
        );
    };

    return (
        <div className="recipe-list">
            <div className="recipe-list-title">
                <h2>Recipes</h2>
                <p>Discover our collection of seafood recipes, complete with cooking tips and fish highlights, designed to make your next seafood meal unforgettable.</p>
            </div>

            <div className="recipe-list-filter">
                <div className="filter">
                    <select id="type" value={selectedType} onChange={handleTypeChange}>
                        <option value="">Seafood Type</option>
                        {types.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="filter">
                    <select id="prepTime" value={selectedPrepTime} onChange={handlePrepTimeChange}>
                        <option value="">Prep Time</option>
                        {prepTimes.map(time => (
                            <option key={time} value={time}>{time}</option>
                        ))}
                    </select>
                </div>
                <div className="filter">
                    <select id="totalTime" value={selectedTotalTime} onChange={handleTotalTimeChange}>
                        <option value="">Total Time</option>
                        {totalTimes.map(time => (
                            <option key={time} value={time}>{time} min</option>
                        ))}
                    </select>
                </div>

                <button className="filter-button" onClick={handleReset}>RESET</button>
            </div>

            <div className="recipe-list-container">
                <RecipeDataList />
            </div>
            <Pagination
                productsPerPage={productsPerPage}
                totalProducts={filteredRecipes.length}
                currentPage={currentPage}
                paginate={paginate}
            />
            <Recommends />
            <SignUpButton />
            <PopUp/>
        </div>

    )
}

export default RecipeList;